<template>
  <div class="refer-friend-box">
    <img class="bn-background" src="@/assets/images/banner/refer_friend/bg.png" alt />
    <img class="left-dec" src="@/assets/images/banner/refer_friend/left_dec.png" alt />
    <img class="left-side-img" :src="require(`@/assets/images/banner/refer_friend/left_${this.lang}.png`)" alt />
    <div class="right-box">
      <div>{{ $t('promotion.referFriends.banner.dec1') }}</div>
      <div>{{ $t('promotion.referFriends.banner.dec2') }}</div>
      <div>{{ $t('promotion.referFriends.banner.dec3') }}</div>
      <div>{{ $t('promotion.referFriends.banner.dec4') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      default: 'en_US'
    }
  }
};
</script>
