<template>
  <div class="easter-box">
    <img src="@/assets/images/banner/easter/c.png" class="c" alt="" />
    <img src="@/assets/images/banner/easter/lb.png" class="lb" alt="" />
    <img src="@/assets/images/banner/easter/tr.png" class="tr" alt="" />
    <img src="@/assets/images/banner/easter/phone.png" class="phone" alt="" />
    <img src="@/assets/images/banner/easter/decorate.png" class="decorate" alt="" />
    <img src="@/assets/images/banner/easter/download.png" class="download" alt="" />
    <img src="@/assets/images/banner/easter/text.png" class="text" alt="" />
    <img src="@/assets/images/banner/easter/textMin.png" class="textMin" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      default: 'en_US'
    }
  }
};
</script>

<style lang="scss">
.easter-box {
  height: 100%;
  position: relative;
  img {
    display: block;
    height: auto;
    position: absolute;
  }
  .c {
    width: 273px;
    top: 0;
    left: 30%;
  }
  .lb {
    width: 100px;
    left: 0;
    bottom: 0;
  }
  .tr {
    width: 120px;
    top: 0;
    right: 0;
  }
  .phone {
    width: 285px;
    bottom: 0px;
    right: 500px;
  }
  .decorate {
    width: 109px;
    left: 45%;
    top: 0;
  }
  .download {
    width: 400px;
    top: 50%;
    right: 45px;
    transform: translateY(-50%);
  }
  .text {
    width: 760px;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
  }
  .textMin {
    display: none;
    width: 300px;
    top: 20%;
    left: 5%;
  }
}

@media (max-width: 1920px) {
  .easter-box {
    .decorate {
      left: 36%;
      top: auto;
      bottom: 0;
    }
    .text {
      width: 600px;
    }
  }
}

@media (max-width: 1768px) {
  .easter-box {
    .decorate {
      left: 40%;
      top: auto;
      bottom: 0;
    }
    .download {
      width: 300px;
      right: 20px;
    }
    .phone {
      width: 260px;
      right: 340px;
    }
    .text {
      width: 600px;
    }
  }
}
@media (max-width: 1520px) {
  .easter-box {
    .decorate {
      left: 40%;
      top: auto;
      bottom: 0;
    }
    .download {
      width: 200px;
      right: 20px;
    }
    .phone {
      width: 260px;
      right: 240px;
    }
    .text {
      width: 540px;
    }
  }
}

@media (max-width: 1360px) {
  .easter-box {
    .decorate {
      left: 40%;
      top: auto;
      bottom: 0;
      width: 80px;
    }
    .download {
      width: 200px;
      right: auto;
      left: 5%;
      top: auto;
      bottom: 5%;
      top: auto;
      transform: translateY(0%);
    }
    .phone {
      width: 260px;
      right: 20px;
    }
    .text {
      top: 35%;
      width: 540px;
    }
  }
}

@media (max-width: 1120px) {
  .easter-box {
    .text {
      width: 460px;
    }
  }
}

@media (max-width: 1040px) {
  .easter-box {
    .text {
      width: 420px;
    }
    .phone {
      width: 200px;
    }
  }
}

@media (max-width: 580px) {
  .easter-box {
    .text {
      display: none;
    }
    .decorate {
      left: 50%;
      top: 10%;
      width: 60px;
    }
    .textMin {
      top: 15%;
      display: block;
      width: 160px;
    }
    .phone {
      right: -20px;
      bottom:-20px;
    }
    .download {
      width: 140px;
    }
    .tr {
      display: none;
    }
    .c {
      width:160px;
      right: 5%;
    }
  }
}
</style>
